
// debugger;


// @ @include('../php/wp-includes/js/jquery/jquery.js');
@@include('../www/wp-includes/js/jquery/jquery-migrate.min.js');
// @ @include('../php/wp-content/plugins/revslider/public/assets/js/jquery.themepunch.tools.min.js');
// @ @include('../php/wp-content/plugins/revslider/public/assets/js/jquery.themepunch.revolution.min.js');
// @ @include('../php/wp-content/plugins/goodlayers-core/plugins/combine/script.js');
@@include('goodlayer_scripts.js');
@@include('../www/wp-content/plugins/goodlayers-core/include/js/page-builder.dev.js');
// @ @include('../php/wp-includes/js/wp-embed.min.js');
// @ @include('../php/wp-includes/js/wp-emoji-release.min.js');


@@include('functions/var_dump.js');
@@include('VanilaJS/Object_sort_keys/func_simple.js');
@@include('VanilaJS/obj_map.js');
@@include('VanilaJS/isEmpty.js');


@@include('../www/wp-content/plugins/easy-fancybox/js/jquery.fancybox.min.js');

@@include('Polyfill/forEach.js');
@@include('Polyfill/map.js');
@@include('Polyfill/FastClone.js');

@@include('arrays/array_remove.js');
@@include('arrays/array_unique.js');
@@include('arrays/clone_obj.js');
@@include('arrays/intersection.js');





@@include('functions_standart/DeclOfNum.js');
@@include('functions_standart/stringToDate.js');
@@include('functions_standart/dateFormat.js');

@@include('Settings/init.js');
@@include('jquery-ui-1.12.1.custom/jquery-ui.min.js');
@@include('jquery-ui-1.12.1.custom/effect.min.js');
// @ @include('../www/wp-content/themes/traveltour/plugins/mmenu/jquery.mmenu.min.js');
// @ @include('../../../_Global/jQuery/SuperFish-menu/dist/js/hoverIntent.js');
@@include('../www/wp-includes/js/hoverIntent.js');
// @ @include('../www/wp-content/themes/traveltour/plugins/superfish/superfish.js');
@@include('VanilaJS/array.min-array.max.js');
@@include('jQuery/jquery.serializeObject.js');








@@include('functions/isAdmin.js');
// @ @include('functions/age_good.js');
// @ @include('functions/vr_schools_list.js');
// @ @include('functions/print_price.js');
// @ @include('functions/inc_input_number.js');
@@include('functions/sidebar_scroll.js');
// @ @include('functions/get_list_find.js');
// @ @include('functions/searchgo.js');
// @ @include('functions/print_prices_dont_work.js');





@@include('functions/Maria.js');

// @ @include('functions/vivod_kurs_price.js');
// @ @include('functions/vivod_check_block_price.js');

// @ @include('functions/prices/depends_if.js');
// @ @include('functions/prices/funcs.js');
// @ @include('functions/prices/smart_price.js');
// @ @include('functions/prices/calc_kurs.js');
// @ @include('functions/prices/calc_1_kurs.js');
// @ @include('functions/prices/calc_currency.js');
// @ @include('functions/prices/dop_opl_price.js');

@@include('functions/calendar.beforeShowDay.js');
@@include('functions/event_click_checkbox.js');

@@include('functions/ready.js');
@@include('catching.js');



@@include('jQuery/toggle/index.js');
// function map_obj(arr, callback) {
//     var ar=[];
//     for (var i in arr) {
//         if (arr.hasOwnProperty(i)){
//             var value = callback.call(arr[i], i);
//             if (value!=null) ar.push(value);
//         }
//     }
//     return ar;
// }


// if (!Object.prototype.map2) {
//     Object.prototype.mapvr = function(callback) {
//         var ar=[];
//         for (var i in this) {
//             if (this.hasOwnProperty(i)){
//                 var value = callback.call(this[i], i);
//                 if (value!=null) ar.push(value);
//             }
//         }
//         return ar;
//     }
// }


// function sticky_block(conteiner_block,sidebar){
//
// }



// if ([].indexOf) {
//     var find = function(array, value) {return array.indexOf(value);}
// } else {
//     var find = function(array, value) {for (var i = 0; i < array.length; i++) {if (array[i] === value) return i;}return -1;}
// }
//







// function change_weeks(e){
//     print_prices();
//     // vivod_kurs_price();
//     if (typeof(e)!="undefined"){e.preventDefault();
// 		scrollToSchoolList();
//     }
// }

/*

todo Возможно надо будет

function change_weeks(e){
	// $('.n_prices-kurs').kurs('calc');
	$('.bl_with_check').kurs('calc');
	$('.bl_with_check>input[name="dopkurs"]').trigger('change');
	$('.bl_dop_opl_i input[name="opl"]').trigger('change');
	vivod_check_block_price_timout();
	// vivod_kurs_price();
	if (typeof(e)!="undefined"){e.preventDefault();
		scrollToSchoolList();
	}
}
*/







function scrollToSchoolList(){
    var el = $('.bl_with_check:has(>input:checked):last');
    var to=0;
    if(el.length>0){
		to=el.offset().top-200;
	}else{
    	el = $("a[name='kurs']");
		if(el.length>0){
			to=el.offset().top-70;
		}else{
			el=$('.school_short_list');
			if(el.length>0){
				to=el.offset().top-100;
			}else{
				to=false;
			}
		}

	}
    if(to!==false) {
		$([document.documentElement, document.body]).animate({
			scrollTop: to
		}, 600);
	}
}












$ = jQuery;

@@include('callback/index.js');

// @ @include('zayavka.js');
// @ @include('script-core.dev.js');





